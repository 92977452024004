<template>
  <SearchLayout
    :pagination="pagination"
    no-items-text="Вы пока что не опубликовали ни один проект"
    create-text="Разместить проект"
    :create-to="{ name: 'project-create' }"
  >
    <template #title>
      <PageTitle>Мои проекты</PageTitle>
    </template>

    <template #default>
      <ProjectSearchResultCard
        v-for="project in projects"
        :key="project.id"
        :project="project"
      />
    </template>
  </SearchLayout>
</template>

<script setup>
import usePagination from '~/composables/usePagination'
import { searchProjects } from '~/api/projects'

useHead({
  title: 'Мои проекты'
})

const {
  loading: pending,
  pagination,
  page,
  items: projects,
  load
} = await usePagination(
  (page, perPage) => searchProjects({
    only_my: 1,
    sort: 'new_first',
    page,
    per_page: perPage
  }),
  ref(5)
)
</script>

<style scoped lang="scss">
.project-my {
  &__content {
    grid-column: span 9;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 64px;
  }
  &__pagination {
    margin-top: 32px;
  }
}
</style>
